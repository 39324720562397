import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/public/img/logo_tribucanet.webp");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/public/img/vente-aux-encheres.webp");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/clients/client1/web1/web/frontend/src/Components/Alert/AlertModal.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/src/Components/Google/GooglePublisherTag.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/clients/client1/web1/web/frontend/src/Components/Search/HomeSearch.tsx");
