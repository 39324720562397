'use client';
import { Prisma } from '@prisma/client';
import { Modal } from 'flowbite-react';
import Image from 'next/image';
import React, { useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { Card, CardContent, CardHeader } from '../ui/card';
import AlertCriteria from './AlertCriteria';
import { ISearchVente } from '@/type';
import { isEmailValid } from '@/functions/global';
import { toast } from 'react-toastify';
import { postAlertes } from '@/app/_server/alertesPrisma';
import { FaRegEnvelope } from 'react-icons/fa6';
import CreateAlertMailWidget from '../Widget/CreateAlertMailWidget';
import { useRouter } from 'next/navigation';

type Props = {
    postion: 'top' | 'bottom' | 'page-biens-vente' | 'sidebar';
    tgis?: Prisma.TgiGetPayload<{}>[];
    regions: Prisma.RegionsGetPayload<{}>[];
    departements: Prisma.DepartementsGetPayload<{}>[];
    typesBiens: Prisma.VentesTypeGetPayload<{}>[];
    isAlerte?: boolean;
};

const AlertModal = ({ postion, tgis, regions, departements, typesBiens, isAlerte }: Props) => {
    const [showModal, setShowModal] = useState(isAlerte);
    const [emails, setEmails] = useState<string[]>([]);
    const [errorEmail, setErrorEmail] = useState<string>('');

    const router = useRouter();

    const [criteriaFields, setCriteriaFields] = useState<ISearchVente>({
        tgi: null,
        typesBiens: null,
        region: null,
        departement: null,
        startDateVente: null,
        endDateVente: null,
        prixMax: null,
    });

    const handleCloseModal = () => {
        setEmails([]);
        setCriteriaFields({
            tgi: null,
            typesBiens: null,
            region: null,
            departement: null,
            startDateVente: null,
            endDateVente: null,
            prixMax: null,
        });
        setErrorEmail('');
        setShowModal(false);
        if (isAlerte) {
            router.replace('/')
        }
    };

    const handleSubmit = async () => {
        setErrorEmail('');

        if (emails.length === 0) {
            setErrorEmail('Veuillez saisir au moins une adresse email');
            toast.error('Veuillez saisir au moins une adresse email');
            return;
        }

        const invalidEmails = emails.filter(email => !isEmailValid(email));
        if (invalidEmails.length > 0) {
            setErrorEmail('Une ou plusieurs adresses email sont invalides: ' + invalidEmails.join(', '));
            toast.error('Une ou plusieurs adresses email sont invalides: ' + invalidEmails.join(', '));
            return;
        }

        postAlertes(emails, criteriaFields)
            .then((res) => {
                toast.success('Alerte créée avec succès');
                handleCloseModal();
            })
            .catch(() => {
                toast.error('Une erreur est survenue lors de la création de l\'alerte');
            });
    };

    return (
        <>
            {postion === 'top' &&
                <button
                    type='button'
                    className='absolute top-0 left-0 h-16 md:h-24 w-16 md:w-24 bg-center bg-no-repeat bg-contain -translate-x-1/4 -translate-y-1/2'
                    style={{ "backgroundImage": "url('/img/creer-alerte.webp')" }}
                    onClick={() => setShowModal(true)}
                    title='Créer une alerte'
                />
                || postion === 'bottom' &&
                <button
                    className='w-full xl:max-w-7xl'
                    onClick={() => setShowModal(true)}
                    title='Créer une alerte'
                >
                    <div className="flex justify-center">
                        <div className="w-full">
                            <Image
                                src="/img/bandeau_alerte.webp"
                                alt="On vous alerte par email"
                                className="w-full"
                                width={1200}
                                height={198}
                            />
                        </div>
                    </div>
                </button>
                || postion === 'page-biens-vente' &&
                <button
                    type='button'
                    className='flex items-center bg-[#019640] hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full shadow-xl'
                    onClick={() => setShowModal(true)}
                    title='Créer une alerte'
                >
                    <FaRegEnvelope className='me-1 text-2xl' />
                    <span className='font-bold'>Cr&eacute;er une alerte</span>
                </button>
                || postion === 'sidebar' &&
                <CreateAlertMailWidget setShowModal={setShowModal} />
            }
            {showModal &&
                <Modal show={showModal} onClose={() => handleCloseModal()} size={'6xl'} position={"top-center"} className='z-[1000] [&>div>div]:max-h-none'>
                    <Modal.Header className='bg-red-600'>
                        <div className="text-base md:text-xl font-bold text-white">Cr&eacute;er votre alerte</div>
                    </Modal.Header>
                    <Modal.Body className='overflow-auto'>
                        <form className='flex flex-col space-y-4 h-full'>
                            <div className="">
                                <label htmlFor='alert-email' className='text-lg font-bold'>Emails</label>
                                <ReactMultiEmail
                                    placeholder='Saisir une ou plusieurs adresses emails'
                                    emails={emails}
                                    onChange={(_emails: string[]) => {
                                        setEmails(_emails);
                                    }}
                                    autoFocus={true}
                                    getLabel={(email, index, removeEmail) => {
                                        return (
                                            <div data-tag key={index}>
                                                <div data-tag-item>{email}</div>
                                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                                    ×
                                                </span>
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                            {errorEmail && <p className='text-red-600'>{errorEmail}</p>}
                            <Card className='flex-grow overflow-auto'>
                                <CardHeader className='py-2 bg-zinc-100'>
                                    <div className='text-lg font-bold'>Déterminez vos critères de recherche</div>
                                </CardHeader>
                                <CardContent>
                                    <p className='my-3'>Pour être alerté dès la mise en ligne d&apos;un bien pouvant vous intéresser</p>
                                    <AlertCriteria
                                        tgis={tgis}
                                        regions={regions}
                                        departements={departements}
                                        typesBiens={typesBiens}
                                        criteriaFields={criteriaFields}
                                        setCriteriaFields={setCriteriaFields}
                                    />
                                </CardContent>
                            </Card>
                            <div className="flex justify-end mt-4">
                                <button
                                    type='button'
                                    className='bg-[#019640] hover:bg-green-600 text-white font-bold py-2 px-4 rounded-sm shadow-xl'
                                    onClick={() => handleSubmit()}
                                >
                                    Cr&eacute;er l&apos;alerte
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};

export default AlertModal;