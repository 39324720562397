import { Img } from '@react-email/components';
import React from 'react';

type Props = 
{
    setShowModal: (show: boolean) => void
}

const CreateAlertMailWidget = ({ setShowModal }: Props) => 
{
    return (
        <div className="flex flex-col w-full mt-4 p-0 cursor-pointer" onClick={() => setShowModal(true)}>
            <Img src={`/img/creer-alerte-left.webp`} alt="Creer une alert email" width={301} height={350} className='object-cover w-full shadow-lg' />
        </div>
    )
}

export default CreateAlertMailWidget;