'use client'
import { ISearchVente} from '@/type'
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { fr } from 'date-fns/locale/fr';
import { DateTime } from 'luxon';
import { FaSearchengin } from 'react-icons/fa6';
import { Prisma } from '@prisma/client';
import Select from 'react-select'
import { useRouter } from 'next/navigation';
import { setCookieSearch } from '@/app/_server/SearchCookie';

type Props = {
    tgis?: Prisma.TgiGetPayload<{}>[]
    regions: Prisma.RegionsGetPayload<{}>[]
    departements: Prisma.DepartementsGetPayload<{}>[]
    typesBiens: Prisma.VentesTypeGetPayload<{}>[]
}

const HomeSearch = ({ tgis, regions, departements, typesBiens}: Props) => {

    const [moreCriteria, setMoreCriteria] = useState(false);  

    const [searchFields, setSearchFields] = useState<ISearchVente>({
        tgi: null,
        typesBiens: null,
        region: null,
        departement: null,
        startDateVente: null,
        endDateVente: null,
        prixMax: null,
        numero: null
    });

    const searchParams = new URLSearchParams();

    const router = useRouter();
    
    Object.entries(searchFields).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach(val => {
                searchParams.append(`${key}`, val.id.toString());
            });
        } else if (value !== null) {
            searchParams.append(key, value);
        }
    });

    const handleSearch = async () => {
        await setCookieSearch(searchParams.toString());
        router.push(`/biens-en-vente?${searchParams.toString()}`)
    }
    
    return (
        <>
            <div className="flex flex-col space-y-3">
                <div className="w-full">
                    <Select
                        id='react-select-2-input'
                        className="basic-single"
                        placeholder="Tribunal Judiciaire"
                        styles={{
                            control: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            container: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            menu: (provided: any) => ({
                                ...provided,
                                width: '100%',
                                zIndex: 1
                            }),
                        }}
                        options={tgis}
                        getOptionLabel={(option: any) => {
                            const ville = option.ville;
                            const prefix = /^[aeiouyAEIOUY]/.test(ville) ? "d'" : "de ";
                            return `Tribunal judiciaire ${prefix}${ville}`;
                        }}
                        getOptionValue={(option: any) => option.id!.toString()}
                        noOptionsMessage={() => "Aucun TJI correspondant"}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selectedOption: any) => {
                            setSearchFields({ ...searchFields, tgi: selectedOption?.id ?? null })
                        }}
                        value={tgis?.find((tgi) => tgi.id === searchFields.tgi)}
                        aria-label='Filtre Tribunal Judiciaire'
                    />
                </div>
                <div className="w-full">
                    <Select
                        id='react-select-3-input'
                        className="basic-single"
                        placeholder="Régions"
                        styles={{
                            control: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            container: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            menu: (provided: any) => ({
                                ...provided,
                                width: '100%',
                                zIndex: 1
                            }),
                        }}
                        options={regions}
                        getOptionLabel={(option: any) => option ? `${option?.nom}` : ''}
                        getOptionValue={(option: any) => option?.nom}
                        noOptionsMessage={() => "Aucune région correspondante"}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selectedOption: any) => {
                            setSearchFields({
                                ...searchFields,
                                region: selectedOption ? selectedOption.nom : null,
                                departement: null
                            })
                        }}
                        value={regions.find(option => option.nom === searchFields.region)}
                        aria-label='Filtre région'
                    />
                </div>
                <div className="w-full">
                    <Select
                        id='react-select-4-input'
                        className="basic-single"
                        placeholder="Département"
                        styles={{
                            control: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            container: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            menu: (provided: any) => ({
                                ...provided,
                                width: '100%',
                                zIndex: 1
                            }),
                        }}
                        options={searchFields.region ? departements.filter((dept) => dept.regionId?.toString() === regions.find((region) => region.nom === searchFields.region)?.id.toString()) : departements}
                        getOptionLabel={(option: any) => option ? `${option?.cp} - ${option?.nom}` : ''}
                        getOptionValue={(option: any) => option?.cp?.toString()}
                        noOptionsMessage={() => "Aucun département correspondant"}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selectedOption: any) => {
                            setSearchFields({ ...searchFields, departement: selectedOption?.cp ?? null, region: regions.find((region) => region.id === selectedOption?.regionId )?.nom  ?? null})
                        }}
                        value={departements.find((departement) => departement.cp === searchFields.departement) ?? null}
                        aria-label='Filtre département'
                    />
                </div>
            </div>
            <div className="flex flex-col space-y-3 mt-4">
                <div className="relative w-full">
                    <input
                        type="text"
                        id="numero-filter"
                        name="numero"
                        placeholder="Numéro de la vente"
                        className="w-full px-2 py-[8px] text-sm grow rounded-md border border-zinc-300 bg-white text-zinc-700 focus:border-zinc-500 focus:ring-zinc-500 focus:ring-1 duration-700 hover:border-zinc-500 hover:ring-zinc-500 hover:ring-1"
                        onChange={(e) => {
                            const val = e.target.value;
                            if (/^\d+$/.test(val) || val === '') {
                                setSearchFields({ ...searchFields, numero: val });
                            }
                        }}
                        value={searchFields.numero ?? ''}
                    />
                    {searchFields.numero && (
                        <button
                            type="button"
                            className="absolute top-1/2 right-3 transform -translate-y-1/2 text-zinc-700 hover:text-zinc-500"
                            onClick={() => setSearchFields({ ...searchFields, numero: '' })}
                        >
                            &#x2715; {/* Croix */}
                        </button>
                    )}
                </div>
                <label className="sr-only" htmlFor='numero-filter'>Filtre numéro de vente</label>
                {/* <div className="relative w-full lg:w-1/3"></div>
                    <div className="relative w-full lg:w-1/3"></div> */}
            </div>
            <div className="flex justify-center my-4">
                <button onClick={() => setMoreCriteria(!moreCriteria)} className="bg-transparent underline font-bold hover:bg-zinc-400 rounded-md p-2">
                    {moreCriteria ? 'Moins de critères' : 'Plus de critères'}
                </button>
            </div>
            {moreCriteria &&
            <>
                <div className="flex flex-col space-y-3 mb-4">
                    <div className="w-full">
                        <Select
                            id='types-biens-filter'
                            placeholder="Type de bien"
                            styles={{
                                control: (provided: any) => ({
                                    ...provided,
                                    width: '100%',
                                }),
                                container: (provided: any) => ({
                                    ...provided,
                                    width: '100%',
                                }),
                                menu: (provided: any) => ({
                                    ...provided,
                                    width: '100%',
                                    zIndex: 1
                                }),
                            }}
                            options={typesBiens}
                            getOptionLabel={(option: any) => `${option.label}`}
                            getOptionValue={(option: any) => option.id!.toString()}
                            noOptionsMessage={() => "Aucun type correspondant"}
                            isClearable={true}
                            isSearchable={true}
                            isMulti={true}
                            onChange={(selectedOptions: any) => {
                                setSearchFields({ ...searchFields, typesBiens: selectedOptions ?? null })
                            }}
                            value={typesBiens.filter((typeBiens) => searchFields.typesBiens?.find((selectedOption) => selectedOption.id === typeBiens.id))}
                            aria-label='Filtre type de biens'
                        />
                    </div>
                    <div className="w-full">
                        <ReactDatePicker
                            id='date-vente-filter'
                            name='dateVente'
                            placeholderText="Dates de ventes (sélectionner une période)"
                            className='w-full px-2 py-[8px] grow rounded-md border border-zinc-300 bg-white text-zinc-700 text-sm focus:border-zinc-500 focus:ring-zinc-500 focus:ring-1 duration-700 hover:border-zinc-500 hover:ring-zinc-500 hover:ring-1'
                            disabledKeyboardNavigation
                            dateFormat="dd/MM/yyyy"
                            onKeyDown={(e) => { e.preventDefault() }}
                            onChange={(dates) => {
                                const [start, end] = dates;
                                setSearchFields({ 
                                    ...searchFields, 
                                    startDateVente: start ? DateTime.fromJSDate(start).toFormat('yyyy-MM-dd') : null,
                                    endDateVente: end ? DateTime.fromJSDate(end).toFormat('yyyy-MM-dd') : null  
                                }
                            )}}
                            locale={fr}
                            selected={searchFields.startDateVente ? DateTime.fromFormat(searchFields.startDateVente, 'yy-MM-dd').toJSDate() : null}
                            startDate={searchFields.startDateVente ? DateTime.fromFormat(searchFields.startDateVente, 'yy-MM-dd').toJSDate() : null}
                            endDate={searchFields.endDateVente ? DateTime.fromFormat(searchFields.endDateVente, 'yy-MM-dd').toJSDate() : null}
                            selectsRange
                            autoComplete='off'
                            isClearable
                        />
                        <label className="sr-only" htmlFor='date-vente-filter'>Filtre date de vente</label>
                    </div>
                    <div className="relative w-full">
                        <input
                            type="text"
                            id="prix-filter"
                            name="prix"
                            placeholder="Prix maximum"
                            className="w-full px-2 py-[8px] text-sm grow rounded-md border border-zinc-300 bg-white text-zinc-700 focus:border-zinc-500 focus:ring-zinc-500 focus:ring-1 duration-700 hover:border-zinc-500 hover:ring-zinc-500 hover:ring-1"
                            onChange={(e) => {
                                const val = e.target.value;
                                if (/^\d+$/.test(val) || val === '') {
                                    setSearchFields({ ...searchFields, prixMax: val });
                                }
                            }}
                            value={searchFields.prixMax ?? ''}
                        />
                        {searchFields.prixMax && (
                            <button
                                type="button"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2 text-zinc-700 hover:text-zinc-500"
                                onClick={() => setSearchFields({ ...searchFields, prixMax: '' })}
                            >
                                &#x2715; {/* Croix */}
                            </button>
                        )}
                    </div>
                    <label className="sr-only" htmlFor='prix-filter'>Filtre date de vente</label>
                </div>
            </>
            }
            <div className="flex justify-center">             
                <button 
                    className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-sm shadow-xl flex items-center" 
                    title='Rechercher'
                    onClick={() => handleSearch()}
                >
                    <FaSearchengin className='text-lg me-1' /><span>Rechercher</span>
                </button>
            </div>
        </>
    )
}

export default HomeSearch